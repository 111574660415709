<!-- // Откомментированно - 01.10.2024 -->

<template>
  <main class="grid-container grow auto-rows-min mt-5">
    <h1 class="text-tekstovyy text-center text-8.5 mt-3 lg:text-8.5 font-700"> Доставка еды в Южно-Сахалинске</h1>
    <div
      class="my-5"
    ></div>
    <Banner />
    <Catalog
      v-if="category.length > 0"
      title="Выберите категорию"
      class="mt-10 lg:mt-18"
      :link="{ to: '/categories', text: 'Все категории еды' }"
      :items="category"
    />
    <Catalog
      title="Кухня"
      class="mt-11 lg:mt-24"
      :link="{ to: '/kuhni', text: 'Все кухни мира' }"
      :items="kuhni"
    />
    <Catalog
      title="Блюда"
      class="mt-11 lg:mt-24"
      :link="{ to: '/bluda', text: 'Все блюда' }"
      :items="bluda"
    />
    <Restaurant
      class="mt-11 lg:mt-24"
      :restaurants
      title="Заведения в&nbspЮжно‑Сахалинске"
      :link="{path:'/restaurants',title:'Все заведения в Южно-Сахалинске'}"
    />

    <!-- Текст в конце главной страницы -->
    <MainEndText
      class="mt-11 lg:mt-24 max-lg:hidden"
      :title="'Доставка еды в Южно-Сахалинске'"
    />
    <!-- Блок в конце главной страницы, статика, просто работает -->
    <MainEndBlock
      class="mt-14 lg:mt-18"
      color="gray"
    />
  </main>
</template>

<script setup lang="ts">
// Импорт необходимых композаблов
import { useProduct } from '~/composables/product/useProduct';
import { useCategory } from '~/composables/category/useCategory';
import { useRestaurant } from '~/composables/restaurant/useRestaurant';
import { useStaticPage } from '~/composables/static-page/useStaticPage';
import { useProductPopup } from '~/composables/popups/useProductPopup';

// Инициализация попапа продукта
const { openPopup } = useProductPopup();

// Установка атрибутов для тега body
useHead({
  bodyAttrs: { class: 'bg-white' }
});

// Получение данных о продуктах, категориях и ресторанах
const { productList, getProductBySlug } = useProduct();
const { categoryList: categories } = useCategory();
const { restaurantList: restaurants } = useRestaurant();

// Фильтрация категорий по типам чтобы верно вывести
const category = computed(() => {
  return categories.value.filter((t) => t.type.description === 'Категория');
});
const kuhni = computed(() => {
  return categories.value.filter((t) => t.type.description === 'Кухня');
});
const bluda = computed(() => {
  return categories.value.filter((t) => t.type.description === 'Блюдо');
});

const $route = useRoute();

// Инициализация обработчиков SEO
const { setSEOTitles } = seoHandlers();

// Получение данных статической страницы
const { getStaticPageByUrl } = useStaticPage();
const { data, error } = await useAsyncData(() => getStaticPageByUrl(`${$route.path}`));

// Установка SEO-заголовков в башку, если данные получены
if (data.value) {
  setSEOTitles({
    seoTitle: data.value?.seo_title || '',
    seoDescription: data.value?.seo_description || '',
    seoKeywords: data.value?.seo_keys || ''
  });
}

// Эта байда получает продукт по слагу, вообще может потом снесем, но пока страницы не бетонизированы, так что держим
async function getAdditionalIngredients () {
  if ($route.query.product_slug) {
    // @ts-ignore
    const res = await getProductBySlug($route.query.product_slug)
      .catch((e) => {
        console.log(e._data.message);
        return null;
      });
    if (res) {
      openPopup({
        currentProduct: res
      });
    }
  }
  return;
}
onMounted(() => {
  getAdditionalIngredients();
});

</script>
